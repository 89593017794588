import { Link, navigate } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import './card.scss'

const Card = ({ sectionArchive, sectionTitle, sectionData, sectionCategory, sectionLink }) => (
  <div className='cardContainer'>
    {!sectionArchive ?
      <h1 className="__containerTitle">{sectionTitle}</h1>
      : null
    }
    <div className='__cardFeed'>
      {sectionData.edges.map(edge => (
        <div key={edge.node.id} className='__card'
          style={{
            backgroundImage: `linear-gradient(
            to bottom,
            rgba(10,10,10,0) 0%,
            rgba(10,10,10,0) 50%,
            rgba(10,10,10,0.7) 100%),
            url(${edge.node.featuredImage.fluid.src})`  
          }}
          onClick={() => navigate(`${sectionLink}/${edge.node.slug}`)}
        >
          <p className='__category'>{edge.node[sectionCategory].name}</p>
          <p className='__title'>{edge.node.title}</p>
        </div>
      ))}
    </div>
    {!sectionArchive ?
      <Link to={sectionLink} className='__viewmore'>View More</Link>
      : null
    }
  </div>
)

Card.propTypes = {
  sectionHero: PropTypes.bool,
  sectionTitle: PropTypes.string,
  sectionData: PropTypes.object,
  sectionCategory: PropTypes.string,
  sectionLink: PropTypes.string
}

Card.defaultProps = {
  sectionArchive: false,
  sectionData: {},
  sectionCategory: ''
}

export default Card